import React, { useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const messageEndRef = useRef(null);
  const textareaRef = useRef(null);
  const containerRef = useRef(null);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      const lineHeight = 20; // 假设每行的高度为20px
      const padding = 30; // 上下padding总和为30px
      const maxHeightDesktop = lineHeight * 10 + padding; // 10行内容的最大高度
      const maxHeightMobile = lineHeight * 4 + padding; // 4行内容的最大高度

      // 重置高度，使得在减少内容时高度可以减小
      textareaRef.current.style.height = 'auto';

      // 动态调整高度
      textareaRef.current.style.height = Math.min(
        textareaRef.current.scrollHeight,
        window.innerWidth <= 600 ? maxHeightMobile : maxHeightDesktop
      ) + 'px';
    }
  };

  useEffect(() => {
    adjustTextareaHeight(); // 调整高度
  }, [input]); // 当输入内容变化时调用

  const adjustContainerHeight = () => {
    if (containerRef.current) {
      const viewportHeight = window.innerHeight;
      containerRef.current.style.minHeight = `${viewportHeight}px`;
    }
  };

  useEffect(() => {
    adjustContainerHeight();
    window.addEventListener('resize', adjustContainerHeight);
    return () => {
      window.removeEventListener('resize', adjustContainerHeight);
    };
  }, []);

  const scrollToMiddle = () => {
    if (messageEndRef.current) {
      const chatWindow = document.getElementById('chatWindow');
      const halfHeight = chatWindow.clientHeight / 2;
      chatWindow.scrollTop = messageEndRef.current.offsetTop - halfHeight;
    }
  };

  useEffect(() => {
    scrollToMiddle();
  }, [messages]);

  const handleSendMessage = async () => {
    if (input) {
      const userMessage = { sender: 'user', text: input };
      const loadingMessage = { sender: 'gpt', text: '生成中...' };
      setMessages([...messages, userMessage, loadingMessage]);
      setInput('');

      try {
        const response = await fetch('https://www.ai-chat.cc/api/chat', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ prompt: input })
        });

        const result = await response.json();
        console.log('API response:', result);

        if (response.ok) {
          const botMessage = { sender: 'gpt', text: result.message };
          setMessages((prevMessages) => 
            prevMessages.map((msg, index) => 
              index === prevMessages.length - 1 ? botMessage : msg
            )
          );
        } else {
          alert(result.message);
          setMessages((prevMessages) => 
            prevMessages.slice(0, prevMessages.length - 1)
          );
        }
      } catch (error) {
        console.error('Error:', error);
        setMessages((prevMessages) => 
          prevMessages.slice(0, prevMessages.length - 1)
        );
      }
    }
  };

  return (
    <section className="chat">
      <div className="container" ref={containerRef}>
        <div id="chatWindow" className="chat-window">
          {messages.map((msg, index) => (
            <div key={index} className={msg.sender === 'user' ? 'user-message' : 'gpt-message'}>
              {msg.sender === 'user' ? (
                msg.text
              ) : (
                <div className="gpt-message-container">
                  <div className="gpt-avatar">
                    <img src="/chatgpt-avatar.png" alt="ChatGPT Avatar" width="24" height="24" />
                  </div>
                  <div className="gpt-text">
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>{msg.text}</ReactMarkdown>
                  </div>
                </div>
              )}
              {index === messages.length - 1 && <div ref={messageEndRef} />}
            </div>
          ))}
        </div>
        <div className="chat-input-container">
          <textarea
            id="messageInput"
            value={input}
            ref={textareaRef}
            onChange={(e) => setInput(e.target.value)}
            placeholder="请输入提示词..."
            data-gramm="false"
            rows="1"
          ></textarea>
          <div className="chat-input-button">
            <button
              id="sendMessageButton"
              onClick={handleSendMessage}
              disabled={!input.trim()} // Disable button if input is empty or only spaces
              style={{ backgroundColor: input.trim() ? '#007bff' : '#c0c0c0' }}
            >
              发送
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Chat;

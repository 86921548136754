import React from 'react';
import Header from './components/Header.js';
import Services from './components/Services.js';
import Chat from './components/Chat.js';
import Footer from './components/Footer.js';
import './chat.css'; // 在外层文件中导入 CSS 文件

function App() {
  return (
    <div>

      <main>
        <Chat />
      </main>
    </div>
  );
}

export default App;
